import styled from "styled-components"
import Page from "../../components/Pages/Page"
import React, { useState } from "react"
import colors from "../../colors"
import { MdMailOutline } from "react-icons/md"
import axios from "axios"
import { API_URL } from "../../constants"

const News = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  padding: 0px 5%;
  width: 70%;
  align-self: center;

  @media (max-width: 980px) {
    width: 100%;
  }

  h1 {
    font-family: 'Poppins-Bold';
    @media (max-width: 425px) {
      font-size: 1.6rem;
    }
    @media (max-width: 320px) {
      font-size: 1.4rem;
    }
  }
  img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
  button {
    background-color: ${colors.primary2};
    padding: 10px 30px;
    border-radius: 8px;
    border: none;
    color: ${colors.white};
    font-family: 'Poppins-Bold';
    margin-top: 12px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 100%;
  }
  .input {
    color: ${colors.primary2};
    margin-top: 12px;
    padding: 10px 20px;
    border: 2px solid ${colors.primary2};
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .icon {
    color: ${colors.primary2};
    font-size: 1.3rem;
    margin-right: 8px;
  }

  input {
    border: none;
    color: ${colors.primary2};
    width: 100%;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
`
const Newsletter = () => {
  const [email, setEmail] = useState(null)
  const [step, setStep] = useState(1)
  const [error, setError] = useState(null)

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const handleSubmit = async () => {
    setError(null)
    if (!email) {
      return setError("Informe o seu email")
    }
    if (!validateEmail(email)) {
      return setError("Informe um email válido")
    }
    try {
      const { data } = await axios.delete(API_URL + `/newsletter`, {
        email,
      })
      if (data.success) {
        setEmail(null)
        setStep(2)
        setError(null)
      }
    } catch (e) {
      setError(e.response.data.message)
    }
  }
  return (
    <Page
      hideHeader
      hideFooter
      title={`Newsletter`}
      description={`Uma newsletter para tornar mais claro o seu caminho para o sucesso e desenvolvimento pessoal por meio de conteúdos sobre hábitos, produtividade e metas.`}
      pageId={"newsletter"}
      forceTheme={"default"}
    >
      <News>
        <img src={require("../../assets/images/logo.png")} />
        <h1> 🧠 Pílulas do Flynow 🧠</h1>
        <br />

        {step === 1 ? (
          <>
            <p>
              Tem certeza que deseja CANCELAR sua INSCRIÇÃO na NEWSLETTER? Você
              não receberá mais e-mails da nossa newsletter.
            </p>

            <div className="input">
              <MdMailOutline className="icon" />
              <input
                placeholder="Seu e-mail cadastrado na newsletter"
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
              />
            </div>
            <button onClick={handleSubmit}>CANCELAR INSCRIÇÃO</button>
          </>
        ) : (
          <>
            <br />
            <h3>Você foi removido da Newsletter!</h3>
            <br />
            {/* <h4>O que esperar da Pílulas do Flynow 🤔</h4> */}
            <p>Você não receberá mais e-mails da nossa newsletter.</p>
          </>
        )}
      </News>
    </Page>
  )
}

export default Newsletter
